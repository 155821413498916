/* eslint-disable */
import React from 'react'

import Surgeon from '../../images/people/surgeon.jpg'
import SurgeonMobile from '../../images/people/surgeon-mobile.jpg'
import CoupleCouchNotebookLaptop from '../../images/people/couple-couch-notebook-laptop.jpg'
import CoupleCouchNotebookLaptopMobile from '../../images/people/couple-couch-notebook-laptop-mobile.jpg'
import Hospital from '../../images/places/hospital.jpg'
import HospitalMobile from '../../images/places/hospital-mobile.jpg'
import vibrantDarkBlueBackground from '../../images/backgrounds/vibrant-dark-blue.jpg'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import humana from '../../images/brand-logos/humana.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import united from '../../images/brand-logos/united-healthcare.svg'

import NarrowBanner from '../../components/NarrowBanner'

import {
  Billboard,
  Carousel,
  Columns,
  Column,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/Header'
import footer from '../../components/Footer'

const Home = () => {
  const { rotatedNumber } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title:
            'Find an in-network hospital near you | Clearlinkinsurance.com',
          description: 'Find and book an in-network hospital near you.',
          canonical: 'https://clearlinkinsurance.com/',
          robots: 'follow,index',
        },
        path: '/find/find-a-hospital',
        promoCode: '170071',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Find an in-network hospital near you | Clearlinkinsurance.com',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-split-content">
          <div className="wrapper">
            <Billboard
              backgroundColor="white"
              alignImageToBottom
              variant="split"
              image={
                <img
                  src={Surgeon}
                  alt="a surgeon looks straight ahead and smiles warmly"
                />
              }
              mobileImage={
                <img
                  src={SurgeonMobile}
                  alt="a surgeon looks straight ahead and smiles warmly"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Medicare: Find a hospital in-network near you
                  </Typography>
                  <Typography variant="h5">
                    At Clearlink Insurance Agency, it's easy for our clients to
                    find hospitals that accept their coverage.
                  </Typography>
                  <LinkButton
                    variant="feature"
                    to="https://my.clearlinkinsurance.com/login"
                    color="pink"
                  >
                    Find a Hospital
                  </LinkButton>
                </>
              }
            />
          </div>
        </div>

        <div className="dual-button-banner">
          <NarrowBanner
            backgroundColor="#F004B9"
            leftButton={
              <LinkButton to={`tel:${rotatedNumber}`} color="white">
                <span className="mobile-text">Call Now</span>
                <span className="desktop-text">
                  Call Now to Speak to an Agent {rotatedNumber}
                </span>
              </LinkButton>
            }
            rightButton={
              <LinkButton
                to="https://my.clearlinkinsurance.com/login"
                color="white"
              >
                User Portal Login
              </LinkButton>
            }
          />
        </div>

        <div className="hide-nav-button">
          <VariableContent
            backgroundColor="light"
            mainContent={
              <>
                <Typography variant="h2">Find a hospital</Typography>
                <Typography variant="body">
                  Click or tap the search button associated with your provider
                  to access your provider’s hospital search page. From there,
                  you can find qualified facilities in your area.
                </Typography>
              </>
            }
          >
            <Stack spacing="l">
              <Columns cardStyled mobileCarousel>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={aetna}
                    alt="Aetna logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://www.aetnamedicare.com/en/find-doctors-hospitals/find-provider.html"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={cigna}
                    alt="Cigna logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://hcpdirectory.cigna.com/web/public/consumer/directory/search?consumerCode=HDC056"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={humana}
                    alt="Humana logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://finder.humana.com/finder/medical?customerId=1"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={united}
                    alt="United Healthcare logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://connect.werally.com/plans/uhc"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={wellcare}
                    alt="Wellcare logo"
                    style={{ maxWidth: '90px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://www.wellcare.com/en/fap"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
        </div>

        <SplitContent
          className="reverse-desktop"
          backgroundColor="white"
          alignImageToBottom={false}
          image={
            <img
              src={CoupleCouchNotebookLaptop}
              alt="a couple sits on couch together and looks at laptop"
            />
          }
          mobileImage={
            <img
              src={CoupleCouchNotebookLaptopMobile}
              alt="a couple sits on couch together and looks at laptop"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Find specialized medical providers
              </Typography>
              <Typography variant="body">
                When you click or tap the link associated with your insurance
                provider, you’ll arrive at your provider’s hospital search
                page. 
              </Typography>
              <Typography variant="body">
                From your provider’s hospital search page, search by location or
                health need to find an appropriate facility covered by your
                provider.
              </Typography>
              <Typography variant="body">
                Some info you may need to complete your search:
              </Typography>
              <List>
                <ListItem>Medicare ID card</ListItem>
                <ListItem>Plan year and type</ListItem>
                <ListItem>Home address</ListItem>
                <ListItem>ZIP code</ListItem>
              </List>
            </>
          }
        />

        <VariableContent
          style={{
            backgroundImage: `url(${vibrantDarkBlueBackground})`,
            color: '#fff',
          }}
          alignMainContent="Center"
          mainContent={
            <>
              <Typography variant="h1">
                Find specialized medical providers
              </Typography>
              <Typography variant="body">
                Not looking for a hospital? Use the button below to return to
                our medicare provider search hub. There, you can search for
                doctors, dentists, and other providers covered by your plan.
              </Typography>
              <LinkButton variant="" to="/find" color="pink">
                Find a healthcare provider
              </LinkButton>
            </>
          }
        ></VariableContent>

        <SplitContent
          backgroundColor="white"
          alignImageToBottom={false}
          image={
            <img
              src={Hospital}
              alt="a woman smiles after a visit to the dentist's office"
            />
          }
          mobileImage={
            <img
              src={HospitalMobile}
              alt="a woman smiles after a visit to the dentist's office"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">Hospital and Medicare facts</Typography>
              <Typography variant="body">
                Your hospital coverage will depend on your particular Medicare
                plan.
              </Typography>
              <List>
                <ListItem>
                  If you have Original Medicare, you are covered in every
                  hospital in America that accepts Medicare patients.
                </ListItem>
                <ListItem>
                  If you have a private Part D plan but use Original Medicare,
                  you still have hospital coverage at every hospital in America
                  that accepts Medicare patients.
                </ListItem>
                <ListItem>
                  If you have a Medicare Advantage plan through a private
                  carrier, be sure to check that nearby hospitals accept your
                  coverage. This is particularly important when you live in a
                  rural area with limited hospital options.
                </ListItem>
              </List>
              <Typography variant="body">
                If you qualify for an enrollment period and want to review your
                plan to include a preferred nearby hospital, call{' '}
                <a href="tel:${rotatedNumber}">{rotatedNumber}</a> to speak to a
                licensed agent.
              </Typography>
            </>
          }
        />

        <VariableContent
          alignMainContent="Center"
          className="quote-slide-carousel"
        >
          <Carousel>
            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">
                    Robert A. from Texas says
                  </Typography>
                  <Typography variant="body">
                    “...definitely went above and beyond what could be expected
                    on trying to clear up a messy situation with an insurance
                    company. When it looked like all was futile, you were able
                    to come through and provide us with the coverage we were
                    looking for.”
                  </Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>

            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">
                    Joseph A. from the U.S. says
                  </Typography>
                  <Typography variant="body">
                    “The agent was informative and helpful. A good experience.”
                  </Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>
          </Carousel>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
